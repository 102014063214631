/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import {
  LaunchStatusEvent,
  LaunchStatusType,
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  InputEmitter,
  DefaultStreamerOptions,
  StreamerStatus,
  Resolution,
  streamResolutionConfiguration
} from '@pureweb/platform-sdk';

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  LaunchRequestOptions,
  VideoStream,
  System
} from '@pureweb/platform-sdk-react';

// import {
//   MediaPermissionsError,
//   MediaPermissionsErrorType,
//   requestMediaPermissions
// } from 'mic-check';

import * as qs from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Icon } from 'semantic-ui-react';
import useAsyncEffect from 'use-async-effect';
import './App.css';
import clientConfig from './client.json';

import { LaunchView } from './Launch';
import logger from './Log';
// import Webcam from "react-webcam";
import Loader from '../src/loader.gif'
import { ObjectType } from 'typescript';
const client: ClientJson = clientConfig as ClientJson;


// Requesting AUDIO permission only:
// requestMediaPermissions()
// 	.then(() => {})
// 	.catch((err: MediaPermissionsError) => {

//     const { type, name, message } = err;
//     console.log("Step Name : "+name);
//     console.log(message);
//     if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
//         // browser does not have permission to access camera or microphone
//     } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
//         // user didn't allow app to access camera or microphone
//     } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
//         // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
//         // (mostly Windows specific problem)
//     } else {
//         // not all error types are handled by this library
//     }

//   });

class ClientJson {
  environmentId?: string;
  launchType?: string;
  projectId?: string;
  modelId?: string;
  version?: string;
  endpoint?: string;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
  useNativeTouchEvents?: boolean;
  resolution?: string;
}

// const videoConstraints = {
//   width: 400,
//   height: 400,
//   facingMode: "user"
// };

// const WebcamComponent = () => (
//   <Webcam videoConstraints={videoConstraints} />  
// );

class ClientOptions {
  // Overridable connection options
  LaunchType?: string;

  // Launch queue configuration
  ProjectId?: string;
  ModelId?: string;
  Version?: string;
  EnvironmentId?: string;
  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;
  UseNativeTouchEvents?: boolean;
  UsePointerLock?: boolean;
  PointerLockRelease?: boolean;
  Resolution?: Resolution;

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }
    if (!this.ModelId) {
      return false;
    }
    return true;
  }
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
  if (props.StreamerStatus === StreamerStatus.Connected || props.StreamerStatus === StreamerStatus.Completed) {
    return <div />;
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>Your browser does not support the necessary WebRTC capabilities.</h3>
      </div>
    );
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    );
  } else {
    content = (
      // <div>

      //   <img src={Loader} alt="" />
      //   <h3>Please wait, your session is loading.</h3>
      // </div>

      <></>
    );
  }
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
      <div style={{ textAlign: 'center' }}>{content}</div>
    </div>
  );
};

interface ViewProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  PointerLockRelease: boolean;
  Resolution: Resolution;
  UnrealStreamState: boolean;
}


const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const handle = useFullScreenHandle();

  //const queryParameters = new URLSearchParams(window.location.search)
  //https://pw.vmersive.com/?UID=1668943859&channelName=akaka&userType=2&name=Shobhit&avatar=1&gameSessionId=34cd5b86-9d51-4527-a080-c7839d980a6f
  // var data = {"UID":queryParameters.get("UID"),"channelName":queryParameters.get("channelName"),"userType":queryParameters.get("userType"),
  // "name":queryParameters.get("name"),"avatar":queryParameters.get("avatar"),
  //"gameSessionId":queryParameters.get("gameSessionId")};
  // console.log(data);
  //props.InputEmitter.EmitUIInteraction(JSON.stringify(data));

  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone = System.Browser().os === 'iOS' && !window.navigator.userAgent.includes('iPad');
  return (
    <>
      {/* <WebcamComponent  /> */}
      <div style={ props.UnrealStreamState ? { height: '100%' , display: 'none' } : { height: '100%'}} id="unreal_stream">
        <FullScreen handle={handle}>
          <IdleTimeout
            Status={props.StreamerStatus}
            WarningThreshold={300}
            ExitThreshold={120}
            WarningCallback={handle.exit}
            ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
          />

          <LoadingView LaunchRequestStatus={props.LaunchRequestStatus} StreamerStatus={props.StreamerStatus} />
          <VideoStream
            VideoRef={videoRef}
            Emitter={props.InputEmitter}
            Stream={props.VideoStream}
            UseNativeTouchEvents={props.UseNativeTouchEvents}
            UsePointerLock={props.UsePointerLock}
            PointerLockRelease={props.PointerLockRelease}
            Resolution={streamResolutionConfiguration(props.Resolution)}
          />

          <Button
            onClick={handle.enter}
            style={{ position: 'absolute', top: 10, right: 10 }}
            className={isIPhone || handle.active || props.StreamerStatus !== StreamerStatus.Connected ? 'hidden' : ''}>
            <Icon name="expand" />
          </Button>

          {/* {props.StreamerStatus !== StreamerStatus.Connected && (
          <img
            alt="PureWeb Logo"
            src="/pureweb.svg"
            style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }}
          />
        )} */}
        </FullScreen>
      </div>
    </>
  );
};

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 0.5;

// Parse query parameters
const query = qs.parse(window.location.search);
const clientOptions: ClientOptions = new ClientOptions();
clientOptions.LaunchType = (query['launchType'] as string) ?? client.launchType;
if (query['collaboration'] && query['collaboration'] == 'true') {
  clientOptions.LaunchType = 'local';
}

clientOptions.Endpoint = (query['endpoint'] as string) ?? client.endpoint;
clientOptions.ProjectId = (query['projectId'] as string) ?? client.projectId;
clientOptions.ModelId = (query['modelId'] as string) ?? client.modelId;
clientOptions.Version = (query['version'] as string) ?? client.version;
clientOptions.EnvironmentId = (query['environmentId'] as string) ?? client.environmentId;
clientOptions.Resolution = (query['resolution'] as Resolution) ?? client.resolution;
clientOptions.Resolution = clientOptions.Resolution ?? Resolution.fhd;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query['usePointerLock'] === undefined ? client.usePointerLock : query['usePointerLock'] === 'true') ?? true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query['pointerLockRelease'] === undefined ? client.pointerLockRelease : query['pointerLockRelease'] === 'true') ??
  true;

clientOptions.ForceRelay = query['forceRelay'] !== undefined ?? false;
clientOptions.UseNativeTouchEvents =
  (query['useNativeTouchEvents'] === undefined
    ? client.useNativeTouchEvents
    : query['useNativeTouchEvents'] === 'true') ?? false;
// Initialize platform reference
const platform = new PlatformNext();
platform.initialize({ endpoint: clientOptions.Endpoint || 'https://api.pureweb.io' });

const App: React.FC = () => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] = useState(false);
  const [modelDefinition, setModelDefinition] = useState(new UndefinedModelDefinition());
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const [launchRequestError, setLaunchRequestError] = useState<Error>();
  const streamerOptions = DefaultStreamerOptions;

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info('Initializing available models: ' + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId);
        await platform.connect();
        logger.info('Agent Connected: ' + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;
        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug('Available models', models);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [clientOptions]);

  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (model: ModelDefinition): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (clientOptions.Version && clientOptions.Version === model.version) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        setModelDefinitionUnavailable(true);
      }
    }
  }, [availableModels]);

  const launchRequestOptions: LaunchRequestOptions = {
    regionOverride: query['regionOverride'] as string,
    virtualizationProviderOverride: query['virtualizationProviderOverride'] as string
  };
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(platform, modelDefinition, launchRequestOptions);
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] = useStreamer(
    platform,
    launchRequest,
    streamerOptions
  );
  const [loading, setLoading] = useState(false);
  const [unrealstream, setUnrealstream] = useState(true);
  const [load, setLoad] = useState({
    load1:false,
    load2:false,
    load3:false,
    load4:false,
    load5:false,
    load6:false,
    load7:false,
    load8:false,
    load9:false,
    load10:false,
    load11:false,
    load12:false,
    load13:false,
    load14:false,
    load15:false,
    load16:false,
    load17:false,
    load18:false,
    load19:false
  });
  
  // const [streamaudio, setStreamaudio] = useState(false);



  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
  }, [streamerStatus]);

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  const launch = async () => {
    setLoading(true);
    audio.load();
    console.log("step1");
    if (clientOptions.LaunchType !== 'local') {
      try {
        await queueLaunchRequest();
      } catch (err) {
        setLaunchRequestError(err);
      }
    }
  };

  //for send audio to unreal
  // useEffect(()=>{
  //   (async () => {
  //   if(streamaudio==true){
  //     const context = new AudioContext();
  //     const microphone = await navigator.mediaDevices.getUserMedia({
  //         audio: true
  //       })
    
  //     const source = context.createMediaStreamSource(microphone);
    
  //     // NEW A: Loading the worklet processor
  //     await context.audioWorklet.addModule("/recorder.worklet.js")
  //     // Create the recorder worklet
  //     const recorder = new AudioWorkletNode(
  //       context,
  //       "recorder.worklet"
  //     )
    
  //     source
  //       .connect(recorder)
  //       .connect(context.destination);
    
  //     recorder.port.onmessage = (e: {
  //       data:  Float32Array
  //     }) => {
  //       console.log(e.data) 
  //       // `data` is a Float32Array array containing our audio samples 
  //       emitter.EmitUIInteraction(e.data);
  //       emitter.EmitUIInteraction("AudioInput : "+JSON.stringify(e.data));
  //     }
  //   }
  // })();

  // },[streamaudio,emitter]);

  // Log status messages
  useEffect(() => {
    logger.info('Status', status, streamerStatus);
  }, [status, streamerStatus]);

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        logger.info('Message: ' + value);
        // Parse the received messages
        const message = JSON.parse(value);

        // Check if our "alert" message is inside and do something with it
        if (message.hasOwnProperty("Loaded")) {


          const queryParameters = new URLSearchParams(window.location.search)
          //https://pw.vmersive.com/?UID=1668943859&channelName=akaka&userType=2&name=Shobhit&avatar=1&gameSessionId=34cd5b86-9d51-4527-a080-c7839d980a6f
          var data = {
            "UID": queryParameters.get("UID"), "channelName": queryParameters.get("channelName"), "userType": queryParameters.get("userType"),
            "name": queryParameters.get("name"), "avatar": queryParameters.get("avatar")
          };
          console.log(data);
          emitter.EmitUIInteraction("UID:" + queryParameters.get("UID"));
          console.log("UID:" + queryParameters.get("UID"));
          emitter.EmitUIInteraction("channelName:" + queryParameters.get("channelName"));
          console.log("channelName:" + queryParameters.get("channelName"));
          emitter.EmitUIInteraction("userType:" + queryParameters.get("userType"));
          console.log("userType:" + queryParameters.get("userType"));
          // emitter.EmitUIInteraction("gameSessionId:"+queryParameters.get("gameSessionId"));
          emitter.EmitUIInteraction("name:" + queryParameters.get("name"));
          console.log("name:" + queryParameters.get("name"));
          emitter.EmitUIInteraction("avatar:" + queryParameters.get("avatar"));
          console.log("avatar:" + queryParameters.get("avatar"));
          emitter.EmitUIInteraction(JSON.stringify(data));
        }

        if (message.hasOwnProperty("LoadComplete")) {
          //code to hide preloader
          setUnrealstream(false);
        }

        // if (message.hasOwnProperty("StartAudio")) {
        //   setStreamaudio(true);
        // }

        // if (message.hasOwnProperty("EndAudio")) {
        //   setStreamaudio(false);
        // }
        
      },
      (err) => {
        logger.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter]);

  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <p>
          Your client has one or more configuration errors. Please consult the{' '}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client"> README </a> for details on how
          to configure the client template.
        </p>
      </div>
    );
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span>The model that you have requested does not exist</span>
      </div>
    );
  }

  if (launchRequestError) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <span>
          {process.env.NODE_ENV === 'development'
            ? `There was an error with the launch request: ${launchRequestError}`
            : 'It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available.'}
        </span>
      </div>
    );
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Disconnected from stream</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Failure during stream</h2>
        <h2>Please refresh to request a new session</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Streamer contribution withdrawn</h2>
      </div>
    );
  }
console.log(loading);
console.log("step2");
  if (loading) {
    var style = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    } as React.CSSProperties;
    var style_none = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      display:'none'
    } as React.CSSProperties;
    

    //increasing counter for preloader
    //70 circle 19 true value
    let loading_counter = JSON.parse(localStorage.getItem('counter') || '{}');
    let load_text = "Get ready for an amazing immersive experience";
    let load_percentage = 0;
    if(loading_counter<5){
      load_text = "Get ready for an amazing immersive experience";
    } else if(loading_counter<7){
      load_text = "Move with arrow keys or WASD";
    }else if(loading_counter<11){
      load_text = "Click anywhere to teleport within the same MetaSpace";
    }else if(loading_counter<17){
      load_text = "Look around by holding down the left mouse button";
    }else if(loading_counter<=19){
      load_text = "We are almost there, just a few more seconds";
    }
    load["load"+ loading_counter as keyof typeof load] = true;
    load_percentage = Math.round((loading_counter/19)*100);
    loading_counter++;
    localStorage.setItem("counter",loading_counter);
    return (
      <>
      <div
      style={unrealstream?style:style_none}>
      <div style={{ textAlign: 'center' }}>
      <div className="loader-view-progress">
        <div className="loader-inner-view">
          <div className="loader-inner">
            <div className="loader-wrapper">
              <svg width={371} height={370} viewBox="0 0 371 370" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="201.173" className={load["load1" as keyof typeof load]? "active":""} cy="5.7247" r={5} transform="rotate(5.14284 201.173 5.7247)" fill="white" fillOpacity="0.2" />
                <circle cx="217.178" className={load["load1" as keyof typeof load]? "active":""} cy="7.89263" r={5} transform="rotate(10.2857 217.178 7.89263)" fill="white" fillOpacity="0.2" />
                <circle cx="232.925" className={load["load1" as keyof typeof load]? "active":""} cy="11.4867" r={5} transform="rotate(15.4286 232.925 11.4867)" fill="white" fillOpacity="0.2" />
                <circle cx="248.286" className={load["load1" as keyof typeof load]? "active":""} cy="16.4777" r={5} transform="rotate(20.5715 248.286 16.4777)" fill="white" fillOpacity="0.2" />
                <circle cx="263.137" className={load["load2" as keyof typeof load]? "active":""} cy="22.8256" r={5} transform="rotate(25.7143 263.137 22.8256)" fill="white" fillOpacity="0.2" />
                <circle cx="277.36"  className={load["load2" as keyof typeof load]? "active":""} cy="30.4792" r={5} transform="rotate(30.8571 277.36 30.4792)" fill="white" fillOpacity="0.2" />
                <circle cx="290.839" className={load["load2" as keyof typeof load]? "active":""} cy="39.377" r={5} transform="rotate(36 290.839 39.377)" fill="white" fillOpacity="0.2" />
                <circle cx="303.467" className={load["load2" as keyof typeof load]? "active":""} cy="49.4471" r={5} transform="rotate(41.1429 303.467 49.4471)" fill="white" fillOpacity="0.2" />
                <circle cx="315.141" className={load["load3" as keyof typeof load]? "active":""} cy="60.6087" r={5} transform="rotate(46.2857 315.141 60.6087)" fill="white" fillOpacity="0.2" />
                <circle cx="325.768" className={load["load3" as keyof typeof load]? "active":""} cy="72.7718" r={5} transform="rotate(51.4286 325.768 72.7718)" fill="white" fillOpacity="0.2" />
                <circle cx="335.261" className={load["load3" as keyof typeof load]? "active":""} cy="85.8385" r={5} transform="rotate(56.5714 335.261 85.8385)" fill="white" fillOpacity="0.2" />
                <circle cx="343.545" className={load["load3" as keyof typeof load]? "active":""} cy="99.7036" r={5} transform="rotate(61.7143 343.545 99.7036)" fill="white" fillOpacity="0.2" />
                <circle cx="350.553" className={load["load4" as keyof typeof load]? "active":""} cy="114.256" r={5} transform="rotate(66.8571 350.553 114.256)" fill="white" fillOpacity="0.2" />
                <circle cx="356.228" className={load["load4" as keyof typeof load]? "active":""} cy="129.377" r={5} transform="rotate(72 356.228 129.377)" fill="white" fillOpacity="0.2" />
                <circle cx="360.525" className={load["load4" as keyof typeof load]? "active":""} cy="144.946" r={5} transform="rotate(77.1429 360.525 144.946)" fill="white" fillOpacity="0.2" />
                <circle cx="363.409" className={load["load4" as keyof typeof load]? "active":""} cy="160.838" r={5} transform="rotate(82.2857 363.409 160.838)" fill="white" fillOpacity="0.2" />
                <circle cx="364.857" className={load["load5" as keyof typeof load]? "active":""} cy="176.924" r={5} transform="rotate(87.4286 364.857 176.924)" fill="white" fillOpacity="0.2" />
                <circle cx="364.857" className={load["load5" as keyof typeof load]? "active":""} cy="193.076" r={5} transform="rotate(92.5714 364.857 193.076)" fill="white" fillOpacity="0.2" />
                <circle cx="363.409" className={load["load5" as keyof typeof load]? "active":""} cy="209.162" r={5} transform="rotate(97.7143 363.409 209.162)" fill="white" fillOpacity="0.2" />
                <circle cx="360.525" className={load["load5" as keyof typeof load]? "active":""} cy="225.054" r={5} transform="rotate(102.857 360.525 225.054)" fill="white" fillOpacity="0.2" />
                <circle cx="356.228" className={load["load6" as keyof typeof load]? "active":""} cy="240.623" r={5} transform="rotate(108 356.228 240.623)" fill="white" fillOpacity="0.2" />
                <circle cx="350.553" className={load["load6" as keyof typeof load]? "active":""} cy="255.745" r={5} transform="rotate(113.143 350.553 255.745)" fill="white" fillOpacity="0.2" />
                <circle cx="343.545" className={load["load6" as keyof typeof load]? "active":""} cy="270.296" r={5} transform="rotate(118.286 343.545 270.296)" fill="white" fillOpacity="0.2" />
                <circle cx="335.261" className={load["load6" as keyof typeof load]? "active":""} cy="284.161" r={5} transform="rotate(123.429 335.261 284.161)" fill="white" fillOpacity="0.2" />
                <circle cx="325.768" className={load["load7" as keyof typeof load]? "active":""} cy="297.228" r={5} transform="rotate(128.571 325.768 297.228)" fill="white" fillOpacity="0.2" />
                <circle cx="315.141" className={load["load7" as keyof typeof load]? "active":""} cy="309.391" r={5} transform="rotate(133.714 315.141 309.391)" fill="white" fillOpacity="0.2" />
                <circle cx="303.467" className={load["load7" as keyof typeof load]? "active":""} cy="320.553" r={5} transform="rotate(138.857 303.467 320.553)" fill="white" fillOpacity="0.2" />
                <circle cx="290.839" className={load["load7" as keyof typeof load]? "active":""} cy="330.623" r={5} transform="rotate(144 290.839 330.623)" fill="white" fillOpacity="0.2" />
                <circle cx="277.36"  className={load["load8" as keyof typeof load]? "active":""} cy="339.521" r={5} transform="rotate(149.143 277.36 339.521)" fill="white" fillOpacity="0.2" />
                <circle cx="263.137" className={load["load8" as keyof typeof load]? "active":""} cy="347.174" r={5} transform="rotate(154.286 263.137 347.174)" fill="white" fillOpacity="0.2" />
                <circle cx="248.286" className={load["load8" as keyof typeof load]? "active":""} cy="353.522" r={5} transform="rotate(159.429 248.286 353.522)" fill="white" fillOpacity="0.2" />
                <circle cx="232.925" className={load["load8" as keyof typeof load]? "active":""} cy="358.513" r={5} transform="rotate(164.571 232.925 358.513)" fill="white" fillOpacity="0.2" />
                <circle cx="217.178" className={load["load9" as keyof typeof load]? "active":""} cy="362.107" r={5} transform="rotate(169.714 217.178 362.107)" fill="white" fillOpacity="0.2" />
                <circle cx="201.173" className={load["load9" as keyof typeof load]? "active":""} cy="364.275" r={5} transform="rotate(174.857 201.173 364.275)" fill="white" fillOpacity="0.2" />
                <circle cx="185.038" className={load["load9" as keyof typeof load]? "active":""} cy={365} r={5} transform="rotate(180 185.038 365)" fill="white" fillOpacity="0.2" />
                <circle cx="168.903" className={load["load9" as keyof typeof load]? "active":""} cy="364.275" r={5} transform="rotate(-174.857 168.903 364.275)" fill="white" fillOpacity="0.2" />
                <circle cx="152.898" className={load["load10" as keyof typeof load]? "active":""} cy="362.107" r={5} transform="rotate(-169.714 152.898 362.107)" fill="white" fillOpacity="0.2" />
                <circle cx="137.151" className={load["load10" as keyof typeof load]?  "active":""} cy="358.513" r={5} transform="rotate(-164.571 137.151 358.513)" fill="white" fillOpacity="0.2" />
                <circle cx="121.791" className={load["load10" as keyof typeof load]?  "active":""} cy="353.522" r={5} transform="rotate(-159.429 121.791 353.522)" fill="white" fillOpacity="0.2" />
                <circle cx="106.939" className={load["load10" as keyof typeof load]?  "active":""} cy="347.174" r={5} transform="rotate(-154.286 106.939 347.174)" fill="white" fillOpacity="0.2" />
                <circle cx="92.7163" className={load["load11" as keyof typeof load]? "active":""} cy="339.521" r={5} transform="rotate(-149.143 92.7163 339.521)" fill="white" fillOpacity="0.2" />
                <circle cx="79.2366" className={load["load11" as keyof typeof load]? "active":""} cy="330.623" r={5} transform="rotate(-144 79.2366 330.623)" fill="white" fillOpacity="0.2" />
                <circle cx="66.6093" className={load["load11" as keyof typeof load]? "active":""} cy="320.553" r={5} transform="rotate(-138.857 66.6093 320.553)" fill="white" fillOpacity="0.2" />
                <circle cx="54.935"  className={load["load11" as keyof typeof load]? "active":""} cy="309.391" r={5} transform="rotate(-133.714 54.935 309.391)" fill="white" fillOpacity="0.2" />
                <circle cx="44.3083" className={load["load12" as keyof typeof load]? "active":""} cy="297.228" r={5} transform="rotate(-128.571 44.3083 297.228)" fill="white" fillOpacity="0.2" />
                <circle cx="34.8151" className={load["load12" as keyof typeof load]? "active":""} cy="284.161" r={5} transform="rotate(-123.429 34.8151 284.161)" fill="white" fillOpacity="0.2" />
                <circle cx="26.5312" className={load["load12" as keyof typeof load]? "active":""} cy="270.296" r={5} transform="rotate(-118.286 26.5312 270.296)" fill="white" fillOpacity="0.2" />
                <circle cx="19.5231" className={load["load12" as keyof typeof load]? "active":""} cy="255.745" r={5} transform="rotate(-113.143 19.5231 255.745)" fill="white" fillOpacity="0.2" />
                <circle cx="13.8479" className={load["load13" as keyof typeof load]? "active":""} cy="240.623" r={5} transform="rotate(-108 13.8479 240.623)" fill="white" fillOpacity="0.2" />
                <circle cx="9.55085" className={load["load13" as keyof typeof load]? "active":""} cy="225.054" r={5} transform="rotate(-102.857 9.55085 225.054)" fill="white" fillOpacity="0.2" />
                <circle cx="6.66713" className={load["load13" as keyof typeof load]? "active":""} cy="209.162" r={5} transform="rotate(-97.7143 6.66713 209.162)" fill="white" fillOpacity="0.2" />
                <circle cx="5.21937" className={load["load13" as keyof typeof load]? "active":""} cy="193.076" r={5} transform="rotate(-92.5714 5.21937 193.076)" fill="white" fillOpacity="0.2" />
                <circle cx="5.21929" className={load["load14" as keyof typeof load]? "active":""} cy="176.924" r={5} transform="rotate(-87.4286 5.21929 176.924)" fill="white" fillOpacity="0.2" />
                <circle cx="6.66718" className={load["load14" as keyof typeof load]? "active":""} cy="160.838" r={5} transform="rotate(-82.2857 6.66718 160.838)" fill="white" fillOpacity="0.2" />
                <circle cx="9.55097" className={load["load14" as keyof typeof load]? "active":""} cy="144.946" r={5} transform="rotate(-77.1429 9.55097 144.946)" fill="white" fillOpacity="0.2" />
                <circle cx="13.848"  className={load["load15" as keyof typeof load]? "active":""} cy="129.377" r={5} transform="rotate(-72 13.848 129.377)" fill="white" fillOpacity="0.2" />
                <circle cx="19.5232" className={load["load15" as keyof typeof load]? "active":""} cy="114.255" r={5} transform="rotate(-66.8571 19.5232 114.255)" fill="white" fillOpacity="0.2" />
                <circle cx="26.5311" className={load["load15" as keyof typeof load]? "active":""} cy="99.7037" r={5} transform="rotate(-61.7143 26.5311 99.7037)" fill="white" fillOpacity="0.2" />
                <circle cx="34.815"  className={load["load16" as keyof typeof load]? "active":""} cy="85.8386" r={5} transform="rotate(-56.5714 34.815 85.8386)" fill="white" fillOpacity="0.2" />
                <circle cx="44.3083" className={load["load16" as keyof typeof load]? "active":""} cy="72.7719" r={5} transform="rotate(-51.4286 44.3083 72.7719)" fill="white" fillOpacity="0.2" />
                <circle cx="54.935"  className={load["load16" as keyof typeof load]? "active":""} cy="60.6087" r={5} transform="rotate(-46.2857 54.935 60.6087)" fill="white" fillOpacity="0.2" />
                <circle cx="66.6093" className={load["load17" as keyof typeof load]? "active":""} cy="49.4471" r={5} transform="rotate(-41.1429 66.6093 49.4471)" fill="white" fillOpacity="0.2" />
                <circle cx="79.2367" className={load["load17" as keyof typeof load]? "active":""} cy="39.3769" r={5} transform="rotate(-36 79.2367 39.3769)" fill="white" fillOpacity="0.2" />
                <circle cx="92.7164" className={load["load17" as keyof typeof load]? "active":""} cy="30.4792" r={5} transform="rotate(-30.8571 92.7164 30.4792)" fill="white" fillOpacity="0.2" />
                <circle cx="106.939" className={load["load18" as keyof typeof load]? "active":""} cy="22.8257" r={5} transform="rotate(-25.7143 106.939 22.8257)" fill="white" fillOpacity="0.2" />
                <circle cx="121.791" className={load["load18" as keyof typeof load]? "active":""} cy="16.4778" r={5} transform="rotate(-20.5715 121.791 16.4778)" fill="white" fillOpacity="0.2" />
                <circle cx="137.151" className={load["load18" as keyof typeof load]? "active":""} cy="11.4867" r={5} transform="rotate(-15.4286 137.151 11.4867)" fill="white" fillOpacity="0.2" />
                <circle cx="152.898" className={load["load19" as keyof typeof load]? "active":""} cy="7.89259" r={5} transform="rotate(-10.2857 152.898 7.89259)" fill="white" fillOpacity="0.2" />
                <circle cx="168.903" className={load["load19" as keyof typeof load]? "active":""} cy="5.72467" r={5} transform="rotate(-5.14284 168.903 5.72467)" fill="white" fillOpacity="0.2" />
                <circle cx="185.038" className={load["load19" as keyof typeof load]? "active":""} cy={5} r={5} fill="white" fillOpacity="0.2" />
              </svg>
            </div>
            <div className="text-wrapper">
              <div className="title">{load_percentage}%</div>
            </div>
          </div>
          <div className="text-inner">{load_text}</div>
        </div>
      </div>
      </div>
    </div>
      <EmbeddedView 
        VideoStream={videoStream}
        StreamerStatus={streamerStatus as StreamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
        UsePointerLock={clientOptions.UsePointerLock!}
        PointerLockRelease={clientOptions.PointerLockRelease!}
        Resolution={clientOptions.Resolution!}
        UnrealStreamState ={unrealstream}
      />
      </>
    );
    
  } else if (clientOptions.LaunchType !== 'local' && !availableModels) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>Initializing...</h2>
      </div>
    );
  } else if (clientOptions.LaunchType !== 'local' && !availableModels?.length) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'none',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <h2>No models are currently available in this environment.</h2>
      </div>
    );
  } else {
    console.log("step3");
    return <LaunchView Launch={launch} />;
  }
};

const AppWrapper: React.FC = () => {
  return System.IsBrowserSupported() ? (
    <App />
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  );
};

export default AppWrapper;
